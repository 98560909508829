import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step base64`}</strong>{` -- encodes and decodes using base64 representation`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step base64
[-d|--decode] [-r|--raw] [-u|--url]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step base64`}</strong>{` implements base64 encoding as specified by RFC 4648.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`-d`}</strong>{`, `}<strong parentName="p">{`--decode`}</strong>{`
decode base64 input`}</p>
    <p><strong parentName="p">{`-r`}</strong>{`, `}<strong parentName="p">{`--raw`}</strong>{`
use the unpadded base64 encoding`}</p>
    <p><strong parentName="p">{`-u`}</strong>{`, `}<strong parentName="p">{`--url`}</strong>{`
use the encoding format typically used in URLs and file names`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Encode to base64 using the standard encoding:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo -n This is the string to encode | step base64
VGhpcyBpcyB0aGUgc3RyaW5nIHRvIGVuY29kZQ==
$ step base64 This is the string to encode
VGhpcyBpcyB0aGUgc3RyaW5nIHRvIGVuY29kZQ==
`}</code></pre>
    <p>{`Decode a base64 encoded string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo VGhpcyBpcyB0aGUgc3RyaW5nIHRvIGVuY29kZQ== | step base64 -d
This is the string to encode
`}</code></pre>
    <p>{`Encode to base64 without padding:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo -n This is the string to encode | step base64 -r
VGhpcyBpcyB0aGUgc3RyaW5nIHRvIGVuY29kZQ
$ step base64 -r This is the string to encode
VGhpcyBpcyB0aGUgc3RyaW5nIHRvIGVuY29kZQ
`}</code></pre>
    <p>{`Encode to base64 using the url encoding:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo 'abc123$%^&*)_+-=~' | step base64 -u
YWJjMTIzJCVeJiooKV8rLT1-Cg==
`}</code></pre>
    <p>{`Decode an url encoded base64 string. The encoding type can be enforced
using the '-u' or '-r' flags, but it will be auto-detected if they are not
passed:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ echo YWJjMTIzJCVeJiooKV8rLT1-Cg== | step base64 -d
abc123$%^&*)_+-=~
$ echo YWJjMTIzJCVeJiooKV8rLT1-Cg== | step base64 -d -u
abc123$%^&*)_+-=~
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      